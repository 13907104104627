import {
  AB,
  CD,
  PD,
  email,
  mobileA,
  mobileC,
  mobileP,
  phone,
} from "../../../components/estates/details/fields/Ansprechpartner";

import { StaticImage } from "gatsby-plugin-image";
import React from "react";
import { Container } from "react-bootstrap";

const Team = () => {
  return (
    <Container>
      <h1 className="gold-header">Team</h1>
      <div
        className="flex-wrapper"
        style={{ minHeight: "300px", marginBottom: "3em" }}
      >
        <span className="m-3">
          <StaticImage
            src={"../../../assets/images/portraits/portrait-christiane.jpg"}
            alt={CD}
            title={CD}
            placeholder="blurred"
            quality={100}
            style={{ maxWidth: "333px" }}
          />
          <h5 className="my-2">{CD}</h5>
          {phone}
          <br />
          {mobileC}
          <br />
          {email}
        </span>
        <span className="m-3">
          <StaticImage
            src={"../../../assets/images/portraits/portrait-philip.jpg"}
            alt={PD}
            title={PD}
            placeholder="blurred"
            quality={100}
            style={{ maxWidth: "333px" }}
          />
          <h5 className="my-2">{PD}</h5>
          {phone}
          <br />
          {mobileP}
          <br />
          {email}
        </span>
        <span className="m-3">
          <StaticImage
            src={"../../../assets/images/portraits/portrait-placeholder.png"}
            alt={AB}
            title={AB}
            placeholder="blurred"
            quality={100}
            style={{ maxWidth: "333px" }}
          />
          <h5 className="my-2">{AB}</h5>
          {phone}
          <br />
          {mobileA}
          <br />
          {email}
        </span>
        <span className="m-3">
          <StaticImage
            src={"../../../assets/images/portraits/portrait-nicole.jpg"}
            alt="Nicole Maas"
            title="Nicole Maas"
            placeholder="blurred"
            quality={100}
            style={{ maxWidth: "333px" }}
          />
          <h5 className="my-2">Nicole Maas</h5>
          {phone}
          <br />
          {email}
        </span>
      </div>
    </Container>
  );
};

export default Team;
