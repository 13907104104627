import ContactForm from "../components/form/ContactForm";
import { Container } from "react-bootstrap";
import Layout from "../components/layout/Layout";
import React from "react";
import Team from "../components/sections/partials/Team";

const ContactPage = () => {
  return (
    <Layout page="kontakt">
      <Team />
      <section className="bg-light-gray pb-5">
        <Container>
          <h2 className="gold-header">Kontakt</h2>
          <ContactForm />
        </Container>
      </section>
    </Layout>
  );
};

export default ContactPage;
